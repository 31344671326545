export default {
  "login/signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter / S'inscrire"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mot de passe"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "loginWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec"])},
  "loginWithFacebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Facebook"])},
  "loginWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Google"])},
  "loginWithApple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Apple"])},
  "loginWithMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec une adresse mail"])},
  "wrongMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail invalide, Verifiez votre adresse mail"])},
  "weakPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe trop faible"])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais mot de passe"])}
}