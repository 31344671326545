import { createApp } from 'vue'
import App from './App.vue'
//import './plugins/bootstrap-vue'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import firebaseConfig from './config/firebase';
import i18n from './i18n'

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics.isSupported()
    .then(result => result ? firebase.analytics() : null);

createApp(App).use(i18n).mount('#app')
