<template>
  <LoginPage/>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossorigin="anonymous">
</template>

<script>
import authentication from './services/authentication';
import isOnWebview from './helpers/isOnWebview';

import LoginPage from './pages/LoginPage.vue'

export default {
  name: 'App',
  components: {
    LoginPage
  },
  setup: function () {
    if (!isOnWebview()) {
      window.location = 'https://asterixvidivici.com';    
    }
    else {
      authentication.listen();
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
* {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
 }
 body {
    margin: 0;
    padding: 0;
 }
#app {
  text-align: center;
}
</style>
