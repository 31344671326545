export default {
  "login/signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung / Einloggen "])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwort"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "loginWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen mit"])},
  "loginWithFacebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Facebook einloggen"])},
  "loginWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Google einloggen"])},
  "loginWithApple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Apple einloggen"])},
  "loginWithMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Email einloggen"])},
  "wrongMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falsches Mailformat"])},
  "weakPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["das Passwort ist zu schwach"])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falsches Passwort"])}
}