export default {
  "login/signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login / Signin"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "loginWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with"])},
  "loginWithFacebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Facebook"])},
  "loginWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google"])},
  "loginWithApple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Apple"])},
  "loginWithMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with email"])},
  "wrongMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong mail format"])},
  "weakPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password too weak"])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])}
}