import firebase from 'firebase/app';
import 'firebase/auth';

import { reactive } from 'vue';

export const state = reactive({
    user: null,
    auth: null,
    loading: true,
});

export default {
    listen: function () {
      firebase.auth().onAuthStateChanged(
        user => {
          return Promise.resolve(user)
            .then(user => null == user ? this.logout() : handleAuthentication(user));
        },
        error => console.exception(error)
      );
  
      return Promise.resolve()
        .then(() => state.loading = true)
        .then(() => firebase.auth().getRedirectResult())
        .catch(handleAuthenticationError)
        .finally(() => state.loading = false)
    },
    isAuthenticated: function () {
      return null != state.user;
    },
    authenticateWithGoogle: function () {
      return this.authenticate(new firebase.auth.GoogleAuthProvider());
    },
    authenticateWithFacebook: function() {
        return this.authenticate(new firebase.auth.FacebookAuthProvider());
    },
    authenticateWithEmail: function(email,password) {
        return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() =>
                firebase.auth().signInWithEmailAndPassword(email,password)
                    .then(UserCredential =>{
                        return handleAuthentication(UserCredential.user)

                    })
            )
            .catch(reason =>{
                if(reason.code === "auth/user-not-found") {
                    return firebase.auth().createUserWithEmailAndPassword(email, password)
                        .catch(reason => {
                            console.debug(reason);//probably week password
                            throw reason
                        })
                }else{
                    throw reason;
                }
            })

    },
    authenticateWithApple: function() {
      const provider = new firebase.auth.OAuthProvider('apple.com');
  
      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({ locale: 'fr' });
  
      return this.authenticate(provider);
    },
    authenticate: function(provider) {
      return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => firebase.auth().signInWithRedirect(provider));
    },
    refreshToken: function () {
      return Promise.resolve(firebase.auth().currentUser)
        .then(user => null == user ? this.logout() : user.getIdToken(true))
        .then(accessToken => null == accessToken ? state.auth.accessToken = accessToken : null)
    },
    logout: function () {
      return firebase.auth().signOut()
        .then(() => state.auth = state.user = null)
    }
}
  
const handleAuthentication = function (user) {
  return user.getIdToken(true)
    .then(accessToken => state.auth = { accessToken, refreshToken: user.refreshToken, uid: user.uid })
    .then(() => state.user = user);
}
  
const handleAuthenticationError = function (error) {
  if ('auth/popup-closed-by-user' === error.code) {
      return; // not an error to handle
  }

  if ('auth/account-exists-with-different-credential' === error.code && /AstvvReview/i.test(navigator.userAgent)) {
    firebase.auth().signInWithEmailAndPassword('apple-demo@hootside.com', 'apple-demo');

    return;
  }

  return error; // TODO
}
  
