export default {
  "login/signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen / Aanmelden"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
  "loginWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login met"])},
  "loginWithFacebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Facebook"])},
  "loginWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Google"])},
  "loginWithApple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Apple"])},
  "loginWithMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met e-mail"])},
  "wrongMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkeerd e-mailformaat"])},
  "weakPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paswoord te zwak"])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkeerd wachtwoord"])}
}